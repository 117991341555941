import React, { PureComponent } from 'react';
import { withCapoeiraSportState } from 'src/ver2/context';

import CalendarPlashka from 'src/ver2/plashki/calendar';
import DataChanger, { compareFunction } from 'src/ver2/containers/dataChanger';
import { groupBy } from 'lodash';
import EventAvatar from 'src/ver2/components/event/avatar';
import { UpperStatus } from 'src/ver2/components/event/upperStatus';
import { eventStatus as eventStatus_ } from 'src/ver2/pages/event/context';
import { eventLinkText } from 'src/ver2/components/event/little';
import Loader from 'src/ver2/components/loader';
import InlargeEventRow from 'src/ver2/components/event/inlarge';

import './styles.css';

import moment from 'moment/min/moment-with-locales';
import 'moment/locale/ru';

class CalendarPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            dateStart: '01.09.2024',
            dateEnd: '01.09.2025',
            Events: {},
            EventsPreliminarily: {},
            loadedEvents: null,
            filter: {
                Types: 'all',
                Region: 'all',
                YearVariant: '2024-2025',
                YearVariantIndex: 0,
                ShowPast: false
            },
            status: 'loadevents',
        }
        this.yearVariants = {
            // '2019-2021': [3, 2],
            // '2021-2022': [1],
            // '2022-2023': [0],
            // '2023-2024': [-1],
            '2019-2021': [5, 4],
            '2021-2022': [3],
            '2022-2023': [2],
            '2023-2024': [1],
            '2024-2025': [0],
        }
    }

    componentDidMount = () => {
        if (this.props.eventsLoaded) {
            this.setState({ status: 'loadcalendar' });
            this.initialLoad()
        }
    }

    componentDidUpdate = ({ eventsLoaded }, { dateStart }) => {
        if (eventsLoaded !== this.props.eventsLoaded) {
            this.setState({ status: 'loadcalendar' });
            this.initialLoad()
        }
        if (dateStart !== this.state.dateStart) {
            this.initialLoad()
        }
    }

    initialLoad = async () => {

        const { client, iamuser } = this.props;
        client.getEventsByRange(
            moment(this.state.dateStart, 'DD.MM.YYYY').format('YYYYMMDD'),
            moment(this.state.dateEnd, 'DD.MM.YYYY').format('YYYYMMDD')
        ).then(async (Events) => {
            // сначала делаем препроцесс и убираем всё, что не опубликовано
            let AllEvents = [];
            let AllPreliminarilyEvents = [];

            if (!this.Championships) {
                this.Championships = await client.getChampionshipsFromServer();
            }

            for (let i = 0; i < Events.length; i++) {
                if (Events[i].NotAnnounsed) {
                    continue;
                }

                if (Events[i].Settings) {
                    const SettingObj = JSON.parse(Events[i].Settings);
                    // смотрим, когда мы можем не увидеть мероприятие школы в календаре
                    if (SettingObj.PermissionMain && SettingObj.PermissionMain.FaceFrom === 'school' && SettingObj.PermissionMain.IsClosed) {
                        if (!iamuser) continue;
                        if (iamuser && iamuser.SchoolId !== SettingObj.PermissionMain.FaceId) continue;
                    }
                    if (SettingObj.PermissionMain && SettingObj.PermissionMain.FaceFrom === 'group' && SettingObj.PermissionMain.IsClosed) {
                        if (!iamuser) continue;
                        if (iamuser && iamuser.GroupIamInId !== SettingObj.PermissionMain.FaceId && iamuser.GroupId !== SettingObj.PermissionMain.FaceId) continue;
                    }

                }


                let Championship = {};

                if (Events[i].Type === 'championship' && Events[i].ChampionshipId) {
                    const championship = (this.Championships && this.Championships[Events[i].ChampionshipId]) ? this.Championships[Events[i].ChampionshipId][0] : null;
                    if (championship) {
                        Championship = { Championship: championship }
                    }
                }

                if (Events[i].IsPreliminarily) {
                    AllPreliminarilyEvents.push({ ...Events[i], ...Championship })
                } else
                    AllEvents.push({ ...Events[i], ...Championship })

            }

            this.setState({
                status: 'loaded',
                AllEvents,
                AllPreliminarilyEvents,
                RowEvents: AllEvents,
                RowPreliminarilyEvents: AllPreliminarilyEvents,
                Events: groupBy(AllEvents, 'Date'),
                EventsPreliminarily: groupBy(AllPreliminarilyEvents, 'StartPreliminarilyDate')
            })
        })
    }

    onChangeYear = (YearVariant, YearVariantIndex) => {
        const filter = { ...this.state.filter, YearVariant, YearVariantIndex }

        const startYear = Number(YearVariant.slice(0, 4)) + YearVariantIndex;
        const endYear = startYear + 1;
        const dateStart = `01.09.${startYear}`;
        const dateEnd = `01.09.${endYear}`;

        this.setState({ dateStart, dateEnd, filter: { ...filter, YearVariant, YearVariantIndex } });
    }

    onChangeData = (filter_) => {

        const filter = { ...this.state.filter, ...filter_ };

        this.setState({ filter });
        this.processFilter(filter);
    }

    processFilter = async (filter_ = null) => {
        const filter = filter_ || this.state.filter;
        // тут надо отобрать, что показывать
        const { AllEvents, AllPreliminarilyEvents } = this.state;
        const { client } = this.props;
        const { Types, Region } = filter;

        if (Types === 'all') {
            this.setState({
                Events: groupBy(AllEvents, 'Date'),
                EventsPreliminarily: groupBy(AllPreliminarilyEvents, 'StartPreliminarilyDate'),
                RowEvents: AllEvents,
                RowPreliminarilyEvents: AllPreliminarilyEvents,
            })
            return;
        }

        let finalEvents = [];
        let finalPreliminarilyEvents = [];

        for (let i = 0; i < AllEvents.length; i++) {
            const Event = AllEvents[i];
            // мы выбираем только соревнования
            if (Types === 'sport') {
                // только если соревнование
                if (Event.Type === 'championship' && Event.ChampionshipId) {
                    const championship = Event.Championship;
                    // если нет типа, то это какая-то хуйня, а не соревы
                    if (championship && championship.Type) {
                        // теперь смотрим выбор по фильтру
                        if (Region === 'all') {
                            finalEvents.push(Event);
                        } else {
                            if (Region === championship.Region) {
                                finalEvents.push(Event);
                            }
                        }
                    }
                }
            }

            if (Types === 'judge' && Event.Type === 'judge') {
                finalEvents.push(Event);
            }

        }

        for (let i = 0; i < AllPreliminarilyEvents.length; i++) {
            const Event = AllPreliminarilyEvents[i];
            // мы выбираем только соревнования
            if (Types === 'sport') {
                // только если соревнование
                if (Event.Type === 'championship' && Event.ChampionshipId) {
                    const championship = Event.Championship;
                    // если нет типа, то это какая-то хуйня, а не соревы
                    if (championship && championship.Type) {
                        // теперь смотрим выбор по фильтру
                        if (Region === 'all') {
                            finalPreliminarilyEvents.push(Event);
                        } else {
                            if (Region === championship.Region) {
                                finalPreliminarilyEvents.push(Event);
                            }
                        }
                    }
                }
            }
            if (Types === 'judge' && Event.Type === 'judge') {
                finalPreliminarilyEvents.push(Event);
            }

        }

        this.setState({
            Events: groupBy(finalEvents, 'Date'),
            EventsPreliminarily: groupBy(finalPreliminarilyEvents, 'StartPreliminarilyDate'),
            RowEvents: finalEvents,
            RowPreliminarilyEvents: finalPreliminarilyEvents,
        })
    }

    render() {

        // надо выбирать исходя из moment
        // const nowMonth = Number(moment().format('MM')) + 3 + 12 * 2;
        // const startShift = nowMonth;

        // const { YearVariant, YearVariantIndex } = this.state.filter;

        // const shift = this.yearVariants[YearVariant][YearVariantIndex];

        // // надо выбирать исходя из moment
        // const nowMonth = Number(moment().format('MM')) + 3 + 12 * shift;
        // const startShift = nowMonth;

        const { client, eventsLoaded } = this.props;
        const { filter, AllEvents, AllPreliminarilyEvents, RowEvents, RowPreliminarilyEvents, status } = this.state;

        if (status === 'loadevents') {
            return <div style={{}} className='calendar_page'>
                <Loader text='Подгрузка мероприятий' />
            </div>
        }

        if (status === 'loadcalendar') {
            return <div style={{}} className='calendar_page'>
                <Loader text='Составление календаря' />
            </div>
        }

        const { YearVariant, YearVariantIndex } = this.state.filter;

        const shift = this.yearVariants[YearVariant][YearVariantIndex];

        // надо выбирать исходя из moment
        const nowMonth = Number(moment().format('MM')) + 3 + 12 * shift;
        const startShift = nowMonth;

        // const nowMonth = Number(moment().format('MM')) + 3 + 12 * 0;
        // const startShift = nowMonth;

        return <div style={{}} className='calendar_page'>
            <this.FilterSeason />
            <div className='calendar_page_upper'>
                <div className='calendar_page_upper_left'>
                    <DataChanger
                        header={<div> Что будете смотреть </div>}
                        data={filter}
                        onButtonClick={this.onRegistrationClick}
                        changeData={this.onChangeData}
                        noButton={true}
                        components={[
                            {
                                name: 'Types',
                                title: 'Вид календаря',
                                type: 'select',
                                options: [
                                    { key: 'all', title: 'Все мероприятия' },
                                    { key: 'sport', title: 'Спортивный календарь' },
                                    { key: 'judge', title: 'Судейские семинары' }
                                ],
                                nextGroup: { title: '' },
                                blockByValue: {
                                    'sport': [
                                        {
                                            name: 'Region',
                                            title: 'Выбор региона',
                                            type: 'select',
                                            options: [
                                                { key: 'all', title: 'Все мероприятия' },
                                                ...Object.keys(client.regions).map((key) => {
                                                    const { title } = client.regions[key];
                                                    return { key, title }
                                                })
                                            ],
                                            neededText: ' '
                                        }
                                    ]
                                }
                            },
                            {
                                name: 'ShowPast',
                                title: '',
                                type: 'checkbox',
                                text: 'Показывать прошедшие?',
                                nextGroup: { title: '' },
                            },
                        ]}
                    />
                </div>
                <div className='calendar_page_upper_right'>
                    {RowEvents ? RowEvents.map((Event) => {
                        return <InlargeEventRow key={'cal_e' + Event.Id} Event={Event} ShowPast={filter.ShowPast} />
                    }) : null}
                    {RowPreliminarilyEvents ? RowPreliminarilyEvents.map((Event) => {
                        return <InlargeEventRow key={'cal_e' + Event.Id} Event={Event} ShowPast={filter.ShowPast} />
                    }) : null}
                </div>
            </div>

            <div style={{}} className='calendar_page_months'>

                <this.CalendarBlock monthTitle={'сентябрь'} monthShift={startShift} />
                <this.CalendarBlock monthTitle={'октябрь'} monthShift={startShift - 1} />
                <this.CalendarBlock monthTitle={'ноябрь'} monthShift={startShift - 2} />
                <this.CalendarBlock monthTitle={'декабрь'} monthShift={startShift - 3} />
                <this.CalendarBlock monthTitle={'январь'} monthShift={startShift - 4} />
                <this.CalendarBlock monthTitle={'февраль'} monthShift={startShift - 5} />

                <this.CalendarBlock monthTitle={'март'} monthShift={startShift - 6} />
                <this.CalendarBlock monthTitle={'апрель'} monthShift={startShift - 7} />
                <this.CalendarBlock monthTitle={'май'} monthShift={startShift - 8} />
                <this.CalendarBlock monthTitle={'июнь'} monthShift={startShift - 9} />
                <this.CalendarBlock monthTitle={'июль'} monthShift={startShift - 10} />
                <this.CalendarBlock monthTitle={'август'} monthShift={startShift - 11} />

            </div>
        </div>
    }

    FilterSeason = () => {


        // this.yearVariants = {
        //     '2019-2021': [2, 1],
        //     '2021-2022': [0],
        //     '2022-2023': [-1],
        // }

        const { YearVariant, YearVariantIndex } = this.state.filter;

        const keys = Object.keys(this.yearVariants);

        return <div className='calendar_page_seasons'>
            {keys.map((key) => {
                if (this.yearVariants[key].length > 1) {
                    const key1 = key.slice(0, 4);
                    const key2 = key.slice(5);
                    let spanClassName1 = '';
                    let spanClassName2 = '';
                    if (YearVariant === key) {
                        if (YearVariantIndex === 0)
                            spanClassName1 = 'calendar_page_seasons_season_key_choosed'

                        if (YearVariantIndex === 1)
                            spanClassName2 = 'calendar_page_seasons_season_key_choosed'

                    }
                    return <div className='calendar_page_seasons_season'>
                        <span onClick={() => { this.onChangeYear(key, 0) }} className={spanClassName1}>{key1}</span>
                        <span>-</span>
                        <span onClick={() => { this.onChangeYear(key, 1) }} className={spanClassName2}>{key2}</span>
                    </div>
                }

                let spanClassName = '';
                if (YearVariant === key) { spanClassName = 'calendar_page_seasons_season_key_choosed' }
                return <div className='calendar_page_seasons_season'>
                    <span onClick={() => { this.onChangeYear(key, 0) }} className={spanClassName}> {key} </span>
                </div>
            })}
        </div>
    }

    // тут у нас сам календарь
    CalendarBlock = ({ monthShift, monthTitle }) => {

        const { Events, EventsPreliminarily } = this.state;

        return <CalendarPlashka
            Events={Events}
            EventsPreliminarily={EventsPreliminarily}
            upperStyle={{ width: 280, height: 230 }}
            noTitle={true}
            header={monthTitle}
            monthShift={monthShift}
        />
    }

    EventRow = ({ Event }) => {

        const { Id, Title, Date: Date_, StartPreliminarilyDate, IsPreliminarily, Championship } = Event;
        const { filter: { ShowPast } } = this.state;
        const { client } = this.props;

        const eventStatus = () => eventStatus_(Event);

        if (!ShowPast && eventStatus() === 'past') return null;

        let region = '';
        if (Championship && Championship.Region && client.regions[Championship.Region]) {
            region = client.regions[Championship.Region].title;
        }

        return <div className='calendar_page_event_row'>
            <div className='calendar_page_event_row_logo'>
                <EventAvatar
                    withExtra={true}
                    eventId={Id}
                    size={42}
                />
            </div>
            <div className='calendar_page_event_row_title'>
                <span>{eventLinkText(Event)}</span>
            </div>
            <div className='calendar_page_event_row_dates'>
                <span>{IsPreliminarily ? moment(StartPreliminarilyDate, 'MM.YYYY').locale('ru').format('MMMM') : moment(Date_, 'DD.MM.YYYY').locale('ru').format('DD MMMM')}</span>
            </div>
            <div className='calendar_page_event_row_status'>
                <UpperStatus {...{ client, eventStatus, noTooltip: true, className: '__status_default' }} />
            </div>
            <div className='calendar_page_event_row_region'>
                <span>{region}</span>
            </div>
        </div>

    }

}


export default withCapoeiraSportState('iamuser, client, getText, eventsLoaded')(CalendarPage);