async function quizConstructor({ TypeData = 'quiz', ActionData, TargetId, Data }) {
    let uri = 'https://' + this.ServerUrl + `/quizConstructor?sessionId=${this.lastSessionId}`;
    if (TypeData) uri += '&TypeData=' + TypeData;
    if (Data) uri += '&Data=' + JSON.stringify(Data);
    if (ActionData) uri += '&ActionData=' + ActionData;
    if (TargetId) uri += '&TargetId=' + TargetId;

    const answer = await this.standartDataResult(uri, { timeout: 3000 });
    if (!answer.error) {
        return answer.result;
    }
    return [];
}

export default {
    quizConstructor
}