import { createContext } from 'react';
import React, { Component } from 'react';
import Client from '../client';
import { showInfoModal } from './modals';

import axios from 'axios';
import { message } from 'antd';

const context = createContext(
  {
    defVal: 'FUUUCK YEAH'
  }
);

const ServerUrl = 'capoeirasport.com';

export default class CapoeiraSportState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iamuser: null,
      // sessionId: 'e8a1l8ku9e',
      // sessionId: 'axfutsdo2p', // КУРИНА
      // sessionId: 'i9cdvxjd5s', // КРЫЛОВ
      // sessionId: '7nuffuw3e6', // ПИЗДЮКОВ
      // sessionId: 'a17oqg9hzq', // РЕВЕККА
      // sessionId: 'lxlc26xfom', // МЕЗЕНЦЕВА
      // sessionId: '0advhz212g', // КОЛЯДА
      // sessionId: '6auc9t12a0', // МАМЧУР САША
      // sessionId: '0ddlchlree', // МАРКИН
      // sessionId: 'dypbkysg14', // МАМЧУР ТЁМА
      // sessionId: 'ce5hng89hr', // КОЗЛОВ
      // sessionId: 'g0hmxapzgi',
      // sessionId: 'scymojraqa', // Хромов
      // sessionId: 'beft8l87e9', // ХРАМОВА
      // sessionId: 'ps3o3sxnfn', // АНДРЮШКИНА
      // sessionId: '72znva7oep', // СТРЕЛЬЦОВА
      // sessionId: '9xs2q7y3he', //ТУРГЕНЕВ
      // sessionId: 'vgldv32q34', // МУРАВЬЕВ
      groupsLoaded: false,
      eventsLoaded: false,
      newsLoaded: false,
      birthdaysLoaded: false,
      achivmentsLoaded: false,
      allLoaded: false,
      actualNotifications: [], // актуальные нотификаторы
      infoModal: null,
      isFooterShowed: true
    };

    let isMobile = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      isMobile = {
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      };

      window.onresize = () => {
        this.setState({
          isMobile: {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
          }
        })
      }
    }

    const mobileNeeded = () => {
      isMobile = {
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth,
      };
      window.onresize = () => {
        this.setState({
          isMobile: {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
          }
        })
      }
    }

    // когда надо тестировать мобилу
    // mobileNeeded();

    this.state.isMobile = isMobile;

    if (props.client) {
      this.client = props.client;
      return;
    }

    this.client = new Client();
    // this.client.initialize(ServerUrl, this.clientLoad);
    this.client.initialize(ServerUrl, this.clientLoad, false);
    this.client.loadFromServer();

    // this.iamuser().then(
    //   () => {
    //     this.client.loadFromServer();
    //   }
    // )

    this.connectionTimer = setInterval(this.connectionTimerTime, 7000);
  }

  componentDidUpdate({ }, { allLoaded }) {
    if (!allLoaded && this.state.allLoaded) {
      this.iamuser()
    }
  }

  componentWillUnmount() {
    if (this.connectionTimer) {
      clearInterval(this.connectionTimer);
      this.connectionTimer = null;
    }
  }

  // это основной таймер. Он, например, смотрит за notificators
  connectionTimerTime = async () => {
    if (!this.state.iamuser) return;
    const actualNotifications = await this.client.getActualNotifications() || [];
    if (actualNotifications.length !== this.state.actualNotifications.length) {
      this.setState({ actualNotifications })
    }
  }

  clientLoad = (subject) => {
    switch (subject) {
      case 'events': {
        return this.setState({ eventsLoaded: true })
      }
      case 'groups': {
        return this.setState({ groupsLoaded: true })
      }
      case 'news': {
        return this.setState({ newsLoaded: true })
      }
      case 'birthdays': {
        return this.setState({ birthdaysLoaded: true })
      }
      case 'achivments': {
        return this.setState({ achivmentsLoaded: true })
      }
      case 'all': {
        return this.setState({ allLoaded: true })
      }
      default: {

      }
    }
  }

  showInfoModalMy = (props) => {

    const { type } = props || {};

    if (type === 'medal') {
      const { medal } = props;
      this.setState({ infoModal: { type, medal } })

      return;
    }

    if (type === 'birthday') {
      const { personId } = props;
      this.setState({ infoModal: { type, personId } })

      return;
    }

    if (type === 'eventNewNews') {
      const { OneNews } = props;
      this.setState({ infoModal: { type, OneNews } })

      return;
    }

    if (type === 'eventDiscription') {
      const { Discription } = props;
      this.setState({ infoModal: { type, Discription } })

      return;
    }

    if (type === 'eventSchoolResults') {
      const { Data, width } = props;
      this.setState({ infoModal: { type, Data, width } })

      return;
    }

    if (type === 'eventGroupResults') {
      const { Data, width } = props;
      this.setState({ infoModal: { type, Data, width } })

      return;
    }

    if (type === 'pdfrules') {
      this.setState({ infoModal: { type } })
      return;
    }

    if (type === 'video') {
      const { videoId } = props;
      this.setState({ infoModal: { type, videoId } })
      return;
    }

    // просто некоторый набор пользователей
    if (type === 'personsList') {
      const { Persons } = props;
      this.setState({ infoModal: { type, Persons } })
      return;
    }

    if (type === 'participatorsList') {
      const { Data } = props;
      this.setState({ infoModal: { type, Data } })
      return;
    }

    if (type === 'betParticipatorsList') {
      const { Data } = props;
      this.setState({ infoModal: { type, Data } })
      return;
    }

    if (type === 'item') {
      const { Item } = props;
      this.setState({ infoModal: { type, Item } })
      return;
    }

    const { isMobile } = this.state;

    return showInfoModal(this.client, props, isMobile);
  }

  closeInfoModal = () => {
    this.setState({ infoModal: null })
  }

  // поменять мои данные
  updateMyData = async (data) => {
    await this.client.updateMe(data);
    await this.iamuser();
  }

  updateMyVSettings = async (VSettings) => {

    const { iamuser } = this.state;
    if (iamuser) {
      await this.client.updateMyVSettings(VSettings);
      const VS = await this.client.getMyVSettings();
      if (VS && VS[0] && VS[0].VSettings) {
        let iamuser_ = { ...iamuser };
        iamuser_.VSettings = VS[0].VSettings;
        this.setState({ iamuser: iamuser_ });
      }
    }
  }

  iSawNotification = async (NotificationId) => {
    await this.client.iSawNotification(NotificationId);
    this.connectionTimerTime();

  }

  doShowFooter = (isFooterShowed = true) => { this.setState({ isFooterShowed }) }

  render() {
    const {
      props: {
        children,
      },
      state: {
        iamuser,
        groupsLoaded,
        eventsLoaded,
        newsLoaded,
        birthdaysLoaded,
        achivmentsLoaded,
        allLoaded,
        infoModal,
        actualNotifications,
        isMobile,
        isFooterShowed
      },
      register,
      login,
      logout,
      showInfoModalMy,
      closeInfoModal,
      updateMyData,
      updateMyVSettings,

      iSawNotification,
      redirect,

      renewFollows,
      renewElements,
      renewParticipation,
      renewCompetitor,
      renewResourses,
      renewQuests,

      doShowFooter
    } = this;

    return (
      <context.Provider value={{
        getText: this.client.getText,
        iamuser,
        actualNotifications,
        iSawNotification,

        client: this.client,
        groupsLoaded,
        eventsLoaded,
        newsLoaded,
        birthdaysLoaded,
        achivmentsLoaded,
        allLoaded,
        isFooterShowed,
        doShowFooter,

        register,
        login,
        logout,
        showInfoModal: showInfoModalMy,
        closeInfoModal,
        infoModal,
        updateMyData,
        updateMyVSettings,
        redirect,

        renewFollows,
        renewElements,
        renewParticipation,
        renewCompetitor,
        renewResourses,
        renewQuests,

        isMobile
      }}>
        {children}
      </context.Provider>
    );
  }

  // эта функция обращается к серверу и по кукам смотрим, мы зареганы или нет
  iamuser = async (sessionId_ = undefined) => {
    const sessionId = sessionId_ || this.state.sessionId || null;
    const uri = sessionId ? 'https://' + ServerUrl + '/iamuser?sessionId=' + sessionId : 'https://' + ServerUrl + '/iamuser';
    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};

      if (result && result.status === 'Sucess') {
        // хотя раньше тут шло целиком! весь result : { status, data }
        const myInvests = await this.client.setNewiamuser(result, sessionId);
        const elData = await this.getElementsData();

        const iamuser_ = { ...result.data, ...myInvests, ...elData };

        this.setState({ iamuser: iamuser_ });

        this.connectionTimerTime();
        return;
      }
    }
    catch (err) {
      console.log('iamuser server error');
      this.setState({ iamuser: null });
      this.client.setNewiamuser(null, null);
      return;
    }
    this.setState({ iamuser: null });
    this.client.setNewiamuser(null, null);
  }

  renewResourses = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const myResourses = await this.client.renewResourses();
      let iamuser_ = { ...iamuser };
      iamuser_.myResourses = myResourses;
      this.setState({ iamuser: iamuser_ });
    }
  }

  renewFollows = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const myFollows = await this.client.renewFollows();
      let iamuser_ = { ...iamuser };
      iamuser_.myFollows = myFollows;
      this.setState({ iamuser: iamuser_ });
    }
  }

  getElementsData = async () => {
    const myElements = await this.client.renewElements();

    // мы просто получили списком мои элементы, надо их получать еще и объектом
    let myElementsObj = {};
    const elements = this.client.getServerElementsAsObject();
    for (let i = 0; i < myElements.length; i++) {
      const { ElementKey, Lvl, Status, AddedDate } = myElements[i];
      if (Status === 'ok' && elements[ElementKey]) {
        myElementsObj[ElementKey] = { ...elements[ElementKey], Lvl, AddedDate };
      }
    }
    return {
      myElements,
      myElementsObj
    }
  }

  renewElements = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const elData = await this.getElementsData();
      this.setState({ iamuser: { ...iamuser, ...elData } });
    }
  }

  renewQuests = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const myQuests = await this.client.renewQuests();
      let iamuser_ = { ...iamuser };
      iamuser_.myQuests = myQuests;
      this.setState({ iamuser: iamuser_ });
    }
  }

  renewParticipation = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const myParticipation = await this.client.renewParticipation();
      let iamuser_ = { ...iamuser };
      iamuser_.myParticipation = myParticipation;
      // console.log('renewParticipation defenation data', iamuser_);
      this.setState({ iamuser: iamuser_ });
    }
  }

  renewCompetitor = async () => {
    const { iamuser } = this.state;
    if (iamuser) {
      const myCompetitor = await this.client.renewCompetitor();
      let iamuser_ = { ...iamuser };
      iamuser_.myCompetitor = myCompetitor;
      // console.log('renewCompetitor defenation data', iamuser_);
      this.setState({ iamuser: iamuser_ });
    }
  }

  register = async ({ privateData, contactsData, schoolData, groupData }) => {
    let data = {
      login: privateData.Login.trim(),
      password: privateData.Password.trim(),
      name: privateData.Name.trim(),
      surname: privateData.Surname.trim(),
      patronymic: privateData.Patronymic.trim(),
      gender: privateData.Gender,
      birthday: privateData.BirthDay,

      email: contactsData.Email,

      schoolId: schoolData.SchoolId,
      cordao: schoolData.Cordao
    }

    if (privateData.Apelido) data.apelido = privateData.Apelido;
    if (privateData.City) data.city = privateData.City;

    if (contactsData.VK) data.vk = contactsData.VK.trim();
    if (contactsData.Instagram) data.instagram = contactsData.Instagram.trim();
    if (contactsData.Tel) data.tel = contactsData.Tel.trim();

    if (groupData && groupData.GroupId && groupData.GroupId !== '0') data.groupId = groupData.GroupId;


    const result = await this.client.serverRegisterNewPerson(data);

    this.login(data.login, data.password);
  }

  login = async (Login, Password) => {

    const uri = 'https://' + ServerUrl + '/auth?login=' + Login + '&password=' + Password;
    let result_;

    try {
      const res = await axios.get(uri);
      const { result } = res.data || {};
      result_ = result || null;
    }
    catch (err) {
      console.log('login server error');
      result_ = null;
    }

    if (!result_ || !result_.status) {
      message.error('Ошибка сервера при попытке войти на сайт');
      return 0;
    }

    if (result_.status === 'Error') {

      if (result_.data && result_.data.reason) {
        message.error('Неверный логин или пароль. Пожалуйста, не создавайте нового пользователя. Воспользуйтесь функцией Забыли для восстановления пароля');
        return 0;
      }

      message.error('Ошибка сервера при попытке войти на сайт');
      return 0;
    }

    message.success('Вы успешно вошли');
    // на всякий случай сохраняем сессию
    this.setState({ sessionId: result_.data.sessionId });
    // и подтягиваем свои данные
    this.iamuser(result_.data.sessionId);
    return 1;
  }

  logout = async () => {
    const uri = 'https://' + ServerUrl + '/logout?sessionId=' + this.state.sessionId;
    try {
      await axios.get(uri);
    }
    catch (err) {
      console.log('server error');
      return;
    }

    this.setState({ sessionId: null });
    this.iamuser();
  }




}

// ТУТ У НАС ИДЁТ ДЕКОРАТОР, КОТОРЫЙ ПОЗВОЛЯЕТ ПОЛУЧАТЬ ИЗ СТЕЙТА НУЖНЫЕ ДАННОМУ КОМПОНЕНТУ ЗНАЧЕНИЯ

export const withCapoeiraSportState = (withProps = '') => {
  return (
    (Wrapped) =>
      (props) => (
        <context.Consumer>
          {
            (context) => (
              <Wrapped
                {...props}
                {...getWhatINeed(withProps, context)}
              />
            )
          }
        </context.Consumer>
      )
  );
};

const getWhatINeed = (withProps, context) => {

  if (withProps === '') return context;

  return withProps.split(',').reduce((memo, value_) => {
    const value = value_.trim();
    if (!context[value]) {

      const value_ = value.split('.');
      if (value_[0] === 'client') {
        return { ...memo, [value_[1]]: context.client[value_[1]()] }
      }

      return memo;
    }
    return { ...memo, [value]: context[value] }
  }, {})


}

