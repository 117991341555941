import React, { PureComponent } from 'react';
import { Tabs } from 'antd';

import { withGroupState } from 'src/ver2/pages/group/context';
import GroupStructurePlashka from '../structure';
import ShaduleComponent from 'src/ver2/pages/stats/components/shadule';
import VisitsGroupPlashka from '../visits';
import TrefaText from 'src/ver2/components/trefaText';

import './style.css';

const { TabPane } = Tabs;

class MainTabsPlashka extends PureComponent {
    render() {
        const { upperStyle: { width, height }, innerId, Group, Discription, isDiscriptionReady } = this.props;



        return <div style={{ width, height }} className='seqpage_mainpage_main_plashka_outer'>
            <div className='seqpage_mainpage_main_plashka_inner' id={innerId}>
                {isDiscriptionReady ?
                    <Tabs destroyInactiveTabPane={true} animated={false} defaultActiveKey={Discription ? '1' : '2'} size="small">
                        <TabPane tab='Инфо' key='1'>
                            <div className='subgroup_page_main_discription'>
                                {
                                    Discription ?
                                        <TrefaText trefaText={Discription.Body} />
                                        :
                                        <span>Описание не задано</span>
                                }
                            </div>
                        </TabPane>
                        <TabPane tab='Структура' key='2'>
                            <GroupStructurePlashka upperStyle={{ height }} />
                        </TabPane>
                        <TabPane tab='Расписание' key='3'>
                            <ShaduleComponent addRequest={null} byCitys groupId={Group.Id} width={width} height={800} />
                        </TabPane>
                        <TabPane tab='Посещения' key='4'>
                            <VisitsGroupPlashka />
                        </TabPane>
                    </Tabs>
                    :
                    <div>Загрузка</div>
                }
            </div>
        </div >
    }
}

export default withGroupState(`

    Group,
    Discription,
    isDiscriptionReady,
    client,
    iamuser,
    isMobile
    `)(MainTabsPlashka)